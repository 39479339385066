import React from "react";
import "./ReviewOptions.css";

const reviewsConfig = {
  Auckland: {
    google: {
      url: "https://search.google.com/local/writereview?placeid=ChIJnwhSx4hHDW0RS-OyPCpCam8",
      img: "pq_copyright_rogs.png",
    },
    nocowboys: {
      url: "http://www.nocowboys.co.nz/businesses/plumbquick/rate",
      img: "review2.png",
    },
  },
  Wellington: {
    google: {
      url: "https://www.google.com/search?q=plumbquick+wellington&sxsrf=APq-WBsRwO_Oxo82Z6OqJzNTLIpj2hj_NA%3A1645444641863&ei=IX4TYreZNOyL9u8Pg52syAg&ved=0ahUKEwj3rYD73pD2AhXshf0HHYMOC4kQ4dUDCA8&uact=5&oq=plumbquick+wellington&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCABDoHCAAQRxCwAzoNCC4QxwEQ0QMQsAMQQzoHCAAQsAMQQzoNCC4QgAQQxwEQrwEQCkoECEEYAEoECEYYAFCCAlifDmCyD2gBcAF4AYAB3AOIAboKkgEGMTAuNC0xmAEAoAEByAEKwAEB&sclient=gws-wiz#lrd=0x6d38afdb481a28d9:0x3d4019c3e6e688c6,1,,,",
      img: "pq_copyright_rogs.png",
    },
    nocowboys: null,
  },
  Christchurch: {
    google: null,
    nocowboys: null,
  },
};

class ReviewOptions extends React.Component {

  showReviewOptions() {
    return (
      <div className='review-options'>
        <h2 className='ui dividing header'>{this.props.city} Review Sites</h2>
        <div className='ui grid'>
          {reviewsConfig[this.props.city].google && (
            <div id='opt1a' className='eight wide column'>
              <a href={reviewsConfig[this.props.city].google.url} target='_blank' rel='noreferrer' className=''>
                <img src={reviewsConfig[this.props.city].google.img} className='ggl ui ' alt='Review' width='214' height='214' border='0' />
              </a>
            </div>
          )}
          {reviewsConfig[this.props.city].nocowboys && (
            <div id='opt2a' className='eight wide column'>
              <a href={reviewsConfig[this.props.city].nocowboys.url} target='_blank' rel='noreferrer'>
                <img src={reviewsConfig[this.props.city].nocowboys.img} className='ui ' alt='Review' width='214' height='214' border='0' />
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }

  showContent() {
    if (!this.props.city) {
      return (
        <div className='review-options'>

<div class="ui segment">
<h2 className='ui dividing header'>Review Sites<div class="ui active inverted dimmer">
    <div class="ui text small loader">Please choose a location...</div>
  </div></h2>
          <div className='ui grid'>
            <div className='eight wide column'>
              <div class='ui placeholder'>
                <div class='image'></div>
              </div>
            </div>

            <div className='eight wide column'>
              <div class='ui placeholder'>
                <div class='image'></div>
              </div>
            </div>
          </div></div>
        </div>
      );
    } else if (this.props.city && !reviewsConfig[this.props.city].google && !reviewsConfig[this.props.city].google) {
      return (
        <div className='review-options'>
          <h2 className='ui dividing header'>No Review Sites For This Location</h2>
        </div>
      );
    } else {
      return this.showReviewOptions();
    }
  }
  render() {
    return this.showContent();
  }
}

export default ReviewOptions;

import React from "react";
import ReactDOM from "react-dom";
import Reviews from "./Reviews";

import "./loader.min.css";

ReactDOM.render(
  <div>

    <p></p>
    <p>
      <img src='plumbquick1.png' width='400' height='100' alt='Plumbquick' />
    </p>
    <p>
      <img src='pq_leave_a_review_2018.png' width='637' height='86' alt='Plumbquick' />
    </p>
    <p>&nbsp;</p>
    <Reviews />
    <p>&nbsp;</p>
  </div>,
  document.getElementById("root")
);

import React from "react";
import ReviewOptions from "./ReviewOptions";
import SelectCity from "./SelectCity";

class Reviews extends React.Component {

  //state = { city: "Auckland" };
  state = { city: "" };
  setCityCallback =(city) => {
    this.setState({ city: city });
  }
  render() {
    return (
      <div className="reviews ui raised very padded text container segment">
        <SelectCity selectedCity={this.state.city} setCityCB={this.setCityCallback} />
        <ReviewOptions city={this.state.city} />
      </div>
    );
  }
}

export default Reviews;

import React from "react";

class SelectCity extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
      this.props.setCityCB(event.target.value);
  }
  getLabel() {
    if (!this.props.selectedCity) {
        return "Please Select a Location";
      } else {
        return "Change Location";
      }
  }
  handleSubmit(event) {
    event.preventDefault();
    return false;
  }
  render(props) {

    return (
      <div className='select-city'>
        <form onSubmit={this.handleSubmit} className='ui form'>
          <div className='field'>
            <label>{this.getLabel()}:</label>

            <select value={this.props.selectedCity} onChange={this.handleChange}>
              <option value=''>Please Select</option>
              <option value='Auckland'>Auckland</option>
              <option value='Wellington'>Wellington</option>
              <option value='Christchurch'>Christchurch</option>
            </select>
          </div>
        </form>
      </div>
    );
  }
}

export default SelectCity;
